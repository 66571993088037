@charset "UTF-8";
@-ms-viewport {
  width: device-width;
}
@keyframes top-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 152, 255, 0.3);
  }
  75% {
    box-shadow: 0 0 0 10px rgba(4, 169, 245, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(4, 169, 245, 0);
  }
}
*, :after, :before {
  box-sizing: border-box;
}

html {
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.5333333333);
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
}

body {
  line-height: 1.8;
  font-size: 18px;
  background: #f5f5f5;
  font-weight: 300;
  cursor: auto;
  overflow-x: hidden;
  margin: 0;
  -webkit-text-size-adjust: none;
}

a {
  text-decoration: none;
  font-weight: 400;
}
a:hover {
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  color: #51aded;
}
a:focus {
  outline: 0;
  text-decoration: none;
}

abbr[data-original-title], abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

abbr[title] {
  border-bottom: 1px dotted;
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.43;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

b,
strong {
  font-weight: 700;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: crosshair;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

blockquote {
  font-size: 17.5px;
  margin: 12px 0;
  border-left: 5px solid;
  padding: 4px 0 5px 10px;
  border-radius: 5px;
}
blockquote footer:before {
  content: "— ";
}
blockquote .small, blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
blockquote .small:before, blockquote small:before {
  content: "— ";
}

blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

cite {
  font-style: normal;
}

code,
pre,
samp {
  font-family: "Fira Code", Consolas, "Courier New", monospace;
}

dfn {
  font-style: italic;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dd,
dt {
  line-height: 1.42857143;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid silver;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  transition: 0.5s;
}

h1, h2, h3,
h4, h5, h6 {
  font-weight: 400;
  margin: 0 0 15px 0;
  font-family: inherit;
  line-height: 1.1;
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1 small, h2 small, h3 small {
  font-size: 65%;
}

h4, h5, h6 {
  margin-top: 25px;
  margin-bottom: 10px;
}
h4 small, h5 small, h6 small {
  font-size: 75%;
}

h1 {
  font-size: 1.5em;
}
h2 {
  font-size: 1.3em;
}
h2.title-h2 {
  margin: 15px -20px;
  padding: 0 20px;
  border-left: 5px solid #51aded;
  font-size: 20px;
  line-height: 40px;
  font-size: 18pt;
}
h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.83em;
}
h6 {
  font-size: 0.67em;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

img {
  vertical-align: middle;
}

code, kbd {
  font-size: 90%;
  color: #fff;
  border-radius: 3px;
  padding: 1px 6px;
  background-color: rgba(108, 108, 108, 0.7764705882);
  white-space: normal;
}

pre:has(code) {
  border-radius: 8px;
  padding-top: 30px !important;
  position: relative;
  overflow: auto;
  background-color: #1d1f21;
  font-size: 14px;
  font-weight: unset;
}
pre:has(code)::before {
  content: " ";
  position: absolute;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #fc625d;
  width: 12px;
  height: 12px;
  left: 10px;
  top: 10px;
  -webkit-box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
  box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
}

pre code {
  white-space: pre;
  background-color: inherit;
  font-size: inherit;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

mark {
  padding: 0.4px 4px;
}

p, ol, ul {
  margin: 0 0 1em 0;
  font-size: 16px;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

optgroup {
  font-weight: 700;
}

ruby {
  margin: 0 2px;
  padding: 0 2px;
  border-radius: 4px;
}

select [multiple], select [size] {
  height: auto;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

small {
  font-size: 85%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 2em 0;
  width: 100%;
}
table *[class*=col-] {
  position: static;
  float: none;
}
table col[class*=col-] {
  display: table-column;
}
table td, table th {
  padding: 6px 12px;
  border: 1px solid #ccc;
  font-size: 14px;
}
table td [class*=col-], table th [class*=col-] {
  display: table-cell;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.btn:active, .btn:focus, .btn:hover {
  background: #393e46 !important;
  color: #fff;
  outline: 0 !important;
  cursor: pointer;
}
.btn.btn-primary {
  border: transparent;
  border-color: #51aded;
  background-color: #51aded;
  color: #fff;
}
.btn.btn-primary:hover {
  border-color: #15b9fa;
  background-color: #15b9fa;
}
.btn.btn-primary:active, .btn.btn-primary:focus {
  border-color: #0895cb;
  background-color: #0895cb;
}

.clearfix::before, .clearfix::after, .container::before, .container::after, .nav::before, .nav::after, .row::before, .row::after {
  display: table;
  content: " ";
}
.clearfix::after, .container::after, .nav::after, .row::after {
  clear: both;
}

[class*=col-md-] {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.form-control {
  padding: 6px 12px;
  line-height: 1.42857143;
  width: 100%;
  color: #555;
  z-index: 2;
  float: left;
}
.form-group {
  margin-bottom: 15px;
}

.hidden {
  display: none !important;
}

.input-group {
  display: flex;
  margin: 30px auto;
  border-collapse: separate;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}
.input-group-addon:first-child {
  border-right: 0;
}

.post-pwd {
  width: 55%;
}

.pull-left, .pull .left, .pullquote-left, .pullquote .left {
  float: left !important;
}
.pull-right, .pull .right, .pullquote-right, .pullquote .right {
  float: right !important;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.useragent {
  font-size: 15px;
  color: #333;
}

[class*=visible-] {
  display: none !important;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

.alert {
  padding: 1rem;
  margin: 2rem 0;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
}
.alert .icon, .alert .text {
  display: flex;
}
.alert .icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.alert .text {
  flex: 1;
  border-left: 1px dashed;
  flex-direction: column;
}
.alert a {
  color: #1e8cdb;
}
.alert ul, .alert p {
  margin: 0 1em !important;
}
.alert p + p {
  margin-top: 0.5em !important;
}
.alert-primary {
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-primary .icon, .alert-primary .text p {
  color: #084298 !important;
}
.alert-success {
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .icon, .alert-success .text p {
  color: #0f5132 !important;
}
.alert-danger {
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .icon, .alert-danger .text p {
  color: #842029 !important;
}
.alert-info {
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .icon, .alert-info .text p {
  color: #055160 !important;
}
.alert-warning {
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .icon, .alert-warning .text p {
  color: #664d03 !important;
}

.panel {
  margin-bottom: 20px;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
  padding: 15px;
}
.panel-title {
  padding-left: 15px;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
}
.panel > .panel-title {
  color: #fff;
}
.panel-danger {
  border-color: #e15554;
}
.panel-danger > .panel-title {
  border-color: #e15554;
  background-color: #e15554;
}
.panel-success {
  border-color: #3bb273;
}
.panel-success > .panel-title {
  border-color: #3bb273;
  background-color: #3bb273;
}
.panel-info {
  border-color: #4d9de0;
}
.panel-info > .panel-title {
  border-color: #4d9de0;
  background-color: #4d9de0;
}
.panel-warning {
  border-color: #ffc320;
}
.panel-warning > .panel-title {
  border-color: #ffc320;
  background-color: #ffc320;
}
.panel-pwd {
  border: 0;
  box-shadow: none;
}

.video-container {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 56.25%;
  height: 0;
}
.video-container :not(:first-child) {
  margin-top: 15px;
}
.video-container embed, .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.kratos-post-content div.xControl {
  margin-top: 20px;
}

.xControl {
  position: relative;
  margin-bottom: 15px;
  background: #f6f6f6;
  border-radius: 5px;
}
.xControl > .xHeading {
  position: relative;
  cursor: pointer;
  padding-left: 15px;
}
.xControl > .xHeading a {
  margin: 0;
  font-size: 16px;
}
.xControl > .xHeading .xIcon {
  transition: 0.3s;
  position: absolute;
  right: 15px;
}
.xControl.active .xIcon {
  transform: rotate(-45deg);
}
.xControl .xContent:not(.pre-open) {
  display: none;
}
.xControl .xContent {
  border-top: 1px solid #e6e6e6;
}
.xControl .xContent .inner {
  padding: 5px 15px;
  color: #333;
}

.hide_notice {
  overflow: hidden;
  padding: 8px 8px 8px 24px;
  background-color: #eee;
  font-size: 18px;
  color: #ffcebb;
  margin-bottom: 15px;
}
.hide_notice a {
  color: #bbcdff;
}

.unhide {
  padding: 8px;
  margin-bottom: 15px;
}
.unhide .info {
  font-size: 12px;
  color: #54ff4e;
}

#snow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none;
  transition: opacity 0.5s;
}
#snow:not(.disabled) {
  background: rgba(225, 225, 225, 0.05);
  opacity: 1;
}
#snow.disabled {
  opacity: 0;
}

#kratos-wrapper {
  /*overflow-x:hidden;*/
  position: relative;
}

#offcanvas-menu {
  position: fixed;
  bottom: 0;
  top: 60px;
  z-index: 1000;
  width: 240px;
  padding: 20px;
  overflow-y: auto;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
  background: rgba(42, 42, 42, 0.9);
}
#offcanvas-menu .ul-me {
  padding-bottom: 60px;
}
#offcanvas-menu ul {
  padding: 0;
  margin: 0;
}
#offcanvas-menu ul .sub-menu {
  display: block !important;
}
#offcanvas-menu ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
#offcanvas-menu ul li a {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.55);
}
#offcanvas-menu ul li > .sub-menu {
  margin-top: 9px;
  padding-left: 20px;
}
#offcanvas-menu ul li .current-menu-item > a {
  color: #51aded;
}

#kratos-header {
  position: relative;
  margin-bottom: 0;
  z-index: 5;
}
#kratos-header #kratos-mobile-topnav .color-logo img {
  max-width: 200px;
  height: 40px;
  margin-top: -5px;
}
#kratos-header #kratos-mobile-topnav .kratos-nav-toggle {
  position: fixed;
  top: 11px;
  right: 15px;
  z-index: 999;
  height: 38px;
}
#kratos-header #kratos-mobile-topnav .kratos-nav-toggle i {
  position: relative;
  display: inline-block;
  zoom: 1;
  width: 28px;
  height: 2.5px;
  color: #fff;
  font: 700 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
#kratos-header #kratos-mobile-topnav .kratos-nav-toggle i::before, #kratos-header #kratos-mobile-topnav .kratos-nav-toggle i::after {
  content: "";
  width: 28px;
  height: 2.5px;
  background: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
#kratos-header #kratos-mobile-topnav .kratos-nav-toggle i::before {
  top: -9px;
}
#kratos-header #kratos-mobile-topnav .kratos-nav-toggle i::after {
  bottom: -9px;
}
#kratos-header #kratos-mobile-topnav .nav-toggle.toon i {
  background: 0 0;
}
#kratos-header #kratos-mobile-topnav .nav-toggle.toon i::before {
  top: 0;
  height: 2px;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
#kratos-header #kratos-mobile-topnav .nav-toggle.toon i::after {
  bottom: 0;
  height: 2px;
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.sf-menu li {
  position: relative;
}
.sf-menu li i {
  margin-right: 0.5em;
}

#kratos-menu-wrap {
  float: left;
}
#kratos-menu-wrap .sf-menu a {
  color: #fff;
  padding: 7px 14px;
  font-weight: 400;
  font-size: 15px;
  border: none;
  display: block;
  position: relative;
}
#kratos-menu-wrap .sf-menu > li:hover > a {
  color: #333;
  background: #eee;
}

.kratos-topnav {
  padding: 0;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 2;
  background: rgba(40, 42, 44, 0.6);
  top: 0;
  transition: 0.5s all;
}

#kratos-desktop-topnav .sf-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
#kratos-desktop-topnav .sf-menu * {
  list-style: none;
}
#kratos-desktop-topnav .sf-menu li {
  position: relative;
}
#kratos-desktop-topnav .sf-menu > li {
  float: left;
}
#kratos-desktop-topnav .sf-menu > li > ul.sub-menu {
  transition: 0.3s all ease-in-out;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: calc(100% + 6px);
  width: 135px;
  margin-top: 6px;
  padding: 10px 5px;
  background: rgba(255, 255, 255, 0.9333333333);
  border-radius: 5px;
  left: -18px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}
#kratos-desktop-topnav .sf-menu > li > ul.sub-menu a {
  letter-spacing: 0;
  padding: 7px 15px !important;
  line-height: 20px;
  color: #848484;
}
#kratos-desktop-topnav .sf-menu > li > ul.sub-menu a:hover {
  color: #51aded;
}
#kratos-desktop-topnav .sf-menu > li > ul.sub-menu li a {
  text-align: center;
}
#kratos-desktop-topnav .sf-menu > li > ul.sub-menu::before {
  position: absolute;
  top: -6px;
  left: 62px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(255, 255, 255, 0.9333333333);
  border-left: 6px solid transparent;
  margin: 0 auto;
  content: "";
}
#kratos-desktop-topnav .sf-menu > li:hover > ul.sub-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
#kratos-desktop-topnav .nav-header {
  position: relative;
  float: left;
  width: 100%;
}
#kratos-desktop-topnav .menu-right {
  float: right;
}

body.scroll-down.nav-up #kratos-desktop-topnav {
  top: -41px;
}

.kratos-cover, .kratos-start {
  position: relative;
  height: 800px;
}
.kratos-cover.kratos-hero-2, .kratos-start.kratos-hero-2 {
  height: 320px;
}
.kratos-cover {
  background-size: cover;
  position: relative;
  background-position: center;
}
.kratos-cover .btn {
  padding: 1em 5em;
  opacity: 0.9;
}
.kratos-cover.kratos-cover-2 {
  height: 320px;
  background-position: top;
}
.kratos-cover .desc {
  top: 50%;
  position: absolute;
  width: 100%;
  margin-top: -50px;
  z-index: 2;
  color: #fff;
  text-shadow: 2px 2px 2px #666;
}
.kratos-cover .desc h2 {
  color: #fff;
  text-transform: none;
  font-size: 40px;
  margin-bottom: 10px;
  display: inline-block;
}
.kratos-cover .desc p, .kratos-cover .desc span {
  color: #fff;
  margin-bottom: 30px;
  font-size: 25px;
  letter-spacing: 1px;
  display: inline-block;
}
.kratos-cover .desc p a, .kratos-cover .desc span a {
  color: #fff;
}
.kratos-cover .desc p a:focus, .kratos-cover .desc p a:hover, .kratos-cover .desc span a:focus, .kratos-cover .desc span a:hover {
  color: #fff !important;
}
.kratos-cover .desc p a:focus.btn, .kratos-cover .desc p a:hover.btn, .kratos-cover .desc span a:focus.btn, .kratos-cover .desc span a:hover.btn {
  border: 2px solid #fff !important;
  background: 0 0 !important;
}

#kratos-blog-post {
  padding: 20px 0;
}
#kratos-blog-post p {
  display: block;
}
#kratos-blog-post .aligncenter {
  margin-left: auto;
  margin-right: auto;
}
#kratos-blog-post .alignleft {
  float: left;
}
#kratos-blog-post .alignright {
  float: right;
}
#kratos-blog-post .alignnone {
  display: inline;
  vertical-align: text-bottom;
}
#kratos-blog-post .row {
  display: flex;
}
#kratos-blog-post .row > * {
  max-width: 100%;
}
#kratos-blog-post .row article {
  margin-bottom: 15px;
}

.pagination {
  padding-left: 0;
  border-radius: 4px;
  margin: 23px 0 7px;
}
.pagination a, .pagination span {
  margin: 0 3px;
  position: relative;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  background-color: #fff;
  color: #626262;
}
.pagination > span.page-number, .pagination > a:hover {
  background-color: #51aded;
  color: #fff !important;
}
.pagination .page-number, .pagination .extend {
  min-width: 36px;
  height: 36px;
  display: inline-block;
}

.post-navigation {
  margin-top: 15px;
}
.post-navigation a {
  color: #5a5a5a;
}
.post-navigation .nav-previous {
  max-width: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding: 7px 18px;
  float: left;
  text-align: left;
}
.post-navigation .nav-next {
  max-width: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding: 7px 18px;
  float: right;
  text-align: right;
}

ul.kratos-post-meta {
  padding: 0;
}
ul.kratos-post-meta > li {
  list-style: none;
  display: inline-block;
  font-size: 13px;
}
ul.kratos-post-meta > li:not(:first-child) {
  margin-left: 12px;
}
ul.kratos-post-meta:not(:last-child) {
  margin-bottom: 0;
}

.kratos-breadcrumb {
  margin: 10px 25px;
}
.kratos-breadcrumb > a, .kratos-breadcrumb span {
  padding: 0 5px;
}

.kratos-status {
  margin: 15px 15px 15px 38px;
  border-left: 1px dotted rgba(123, 123, 123, 0.4823529412);
  position: relative;
}
.kratos-status.kratos-status-post {
  margin: 0 15px 0 38px;
}
.kratos-status > i:first-child {
  position: absolute;
  top: calc(50% - 0.5em);
  left: -8px;
}
.kratos-status-inner {
  margin-left: 30px;
}
.kratos-status-inner footer {
  font-size: 12px;
}
.kratos-status-inner .kratos-status-content p {
  margin: 0;
}
.kratos-status-inner .kratos-status-content img {
  max-width: 100%;
}

.kratos-hentry {
  margin-bottom: 25px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.kratos-hentry.kratos-post-inner {
  margin-bottom: 0;
}

.container article:last-of-type {
  margin: 0;
}
.container article.kratos-hentry:hover {
  box-shadow: 0 8px 15px rgba(146, 146, 146, 0.39);
}

.container article .kratos-post-nav {
  padding: 10px 20px 10px 20px;
  color: #5a5a5a;
}
.container article .kratos-post-nav a {
  color: #5a5a5a;
}

.kratos-post-header-title {
  color: #1c1c1c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.5;
  margin: 20px;
}

.kratos-post-inner {
  padding: 25px 10px 18px 10px;
}
.kratos-post-inner div:last-child.kratos-copyright {
  margin-bottom: 20px;
}
.kratos-post-inner .kratos-copyright {
  background-color: rgba(220, 220, 220, 0.7);
  margin: 25px 0 0;
  border-radius: 5px;
}
.kratos-post-inner .kratos-copyright h5 {
  margin: 15px;
  color: #666;
  font-size: 13px;
  font-weight: 300;
}
.kratos-post-inner .kratos-copyright a {
  color: #777;
}

.kratos-post-inner-new {
  margin-left: 255px;
  height: 160px;
  padding: 21px 20px 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kratos-post-inner-new header .label-link {
  position: relative;
  display: inline-block;
  padding: 4px 6px 3px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  background-color: #51aded;
  margin-right: 5px;
  position: relative;
  top: -2px;
  border-radius: 0;
  cursor: pointer;
}
.kratos-post-inner-new header .label-link::after {
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: top;
  content: "";
  top: 6px;
  right: -4px;
  border-left: 4px solid #51aded;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}
.kratos-post-inner-new header .label-link:hover {
  color: #fff !important;
}

.col-md-8 {
  width: 100%;
}

.kratos-post-content {
  word-break: break-word;
  word-wrap: break-word;
  margin: 10px;
}
.kratos-post-content p {
  margin-bottom: 1em;
  font-size: 16px;
  color: #333;
  margin: 1em;
}
.kratos-post-content img {
  height: auto;
  max-width: 100%;
  display: block;
  margin: 1em auto;
  border: 2px solid #bbb;
  border-radius: 2px;
}
.kratos-post-content embed {
  max-width: 100%;
}
.kratos-post-content div {
  margin: auto;
}
.kratos-post-content .caption {
  display: block;
  padding: 5px;
  font-size: 14px;
  text-align: center;
}
.kratos-post-content .kratos-post-inner-toc {
  display: none !important;
  border: 2px dashed #ddd;
  border-radius: 10px;
  padding-top: 15px;
}
@media (max-width: 991px) {
  .kratos-post-content .kratos-post-inner-toc {
    display: block !important;
  }
}
@media (max-width: 768px) {
  .kratos-post-content .kratos-post-inner-toc .toc {
    padding-left: 20px;
  }
}

.kratos-entry-header {
  margin-bottom: 15px;
}

.kratos-entry-title {
  margin: 0 0 15px;
  font-size: 30px;
}
.kratos-entry-title a {
  color: #1c1c1c;
  -webkit-transition: color 0.3s;
  transition: 0.3s;
  text-decoration: none;
  font-size: 25px;
}
.kratos-entry-title a:hover {
  color: #618eba;
}
.kratos-entry-title-new {
  display: inline;
  margin: 0;
  font-size: 21px;
}
.kratos-entry-title-new strong {
  margin-left: 0.5em;
}
.kratos-entry-title-new sub {
  display: inline-block;
}

.kratos-entry-thumb-new {
  position: relative;
  float: left;
  width: 240px;
  height: 145px;
  overflow: hidden;
  margin: 20px;
  padding: 0;
}
.kratos-entry-thumb-new img {
  position: absolute;
  padding: 2px;
  border: 1px solid #f3f3f3;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.kratos-entry-border-new {
  position: relative;
}
.kratos-entry-border-new .kratos-post-meta-new {
  padding: 3px 20px;
  border-top: 1px solid #f3f3f3;
  line-height: 27px;
  overflow: hidden;
  display: block;
  clear: both;
  font-size: 12px;
  background: rgba(253, 253, 253, 0.7);
}
.kratos-entry-border-new .kratos-post-meta-new a i {
  margin-left: 0.3em;
  margin-right: 2px;
}
.kratos-entry-border-new .kratos-post-meta-new .read-more {
  margin-right: 0;
}
.kratos-entry-border-new .kratos-post-meta-new > span > * {
  display: inline-block;
}
.kratos-entry-border-new .stickyimg {
  position: absolute;
  z-index: 2;
  left: 100%;
  margin-left: -65px;
  user-select: none;
}
.kratos-entry-border-new a {
  color: #333;
}
.kratos-entry-border-new .pin-top.wrapper {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 1;
  border-radius: 50%;
  animation: top-pulse 3s infinite;
}
.kratos-entry-border-new .pin-top.inner {
  width: 20px;
  height: 20px;
  background-color: rgba(81, 173, 237, 0.8666666667);
  border-radius: 50%;
}

.kratos-entry-header {
  margin-bottom: 0;
}
.kratos-entry-header .kratos-post-meta a {
  color: #777676;
}
.kratos-entry-header-new {
  margin: 3px 0 6px;
}

.kratos-entry-content p {
  font-size: 16px;
}
.kratos-entry-content-new {
  text-overflow: ellipsis;
}
.kratos-entry-content-new p {
  font-size: 15px;
}

.kratos-entry-footer {
  color: #9d9d9d;
  margin: 10px;
}
.kratos-entry-footer .post-like-donate {
  margin-top: 15px;
  margin-bottom: 20px;
}
.kratos-entry-footer .post-like-donate a {
  display: inline-block;
  margin: 0 2px;
  width: 130px;
  height: 40px;
  border: 1px solid #e87461;
  border-radius: 0;
  box-shadow: none;
  color: #e87461;
  text-align: center;
  text-decoration: none;
  font-size: 14.5px;
  line-height: 40px;
  transition: all 0.3s ease;
}
.kratos-entry-footer .post-like-donate a.read-more:hover {
  background: #e87461;
  color: #fff !important;
}
.kratos-entry-footer .post-like-donate .donate {
  margin-left: 10px;
  border-color: #3496e6;
  color: #3496e6;
}
.kratos-entry-footer .post-like-donate .donate:hover {
  background-color: #3496e6;
  color: #fff !important;
}
.kratos-entry-footer .post-like-donate .share {
  border-color: #78ce79;
  color: #78ce79;
}
.kratos-entry-footer .post-like-donate .share:hover {
  background-color: #78ce79;
  color: #fff !important;
}
.kratos-entry-footer .post-like-donate .share-plain {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.kratos-entry-footer .post-like-donate .share-plain :hover {
  color: #fff;
}
.kratos-entry-footer .post-like-donate .share-plain .icon-wrap {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.kratos-entry-footer .post-like-donate .share-plain.qq {
  border-color: #00bfff;
  color: #00bfff;
}
.kratos-entry-footer .post-like-donate .share-plain.qq:hover {
  background-color: #00bfff;
}
.kratos-entry-footer .post-like-donate .share-plain.qzone {
  border-color: #fece00;
  color: #fece00;
}
.kratos-entry-footer .post-like-donate .share-plain.qzone:hover {
  background-color: #fece00;
}
.kratos-entry-footer .post-like-donate .share-plain.weixin {
  border-color: #38ad5a;
  color: #38ad5a;
}
.kratos-entry-footer .post-like-donate .share-plain.weixin:hover {
  background-color: #38ad5a;
}
.kratos-entry-footer .post-like-donate .share-plain.weibo {
  border-color: #e6162d;
  color: #e6162d;
}
.kratos-entry-footer .post-like-donate .share-plain.weibo:hover {
  background-color: #e6162d;
}
.kratos-entry-footer .post-like-donate .share-plain.twitter {
  border-color: #55acee;
  color: #55acee;
}
.kratos-entry-footer .post-like-donate .share-plain.twitter:hover {
  background-color: #55acee;
}
.kratos-entry-footer .post-like-donate .share-plain.facebook {
  border-color: #3e569b;
  color: #3e569b;
}
.kratos-entry-footer .post-like-donate .share-plain.facebook:hover {
  background-color: #3e569b;
}

.donate-box .meta-pay {
  margin-top: 12px;
  margin-bottom: 12px;
  color: #333;
}
.donate-box .qr-pay img {
  width: 200px;
  height: 200px;
}
.donate-box .choose-pay {
  margin-top: 6px;
}
.donate-box .choose-pay input {
  display: none;
}
.donate-box .choose-pay input:checked + label {
  border-color: #0092ee;
  color: #0092ee;
}
.donate-box .pay-button {
  margin: 10px 5px;
  width: 132px;
  height: 45px;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.donate-box .pay-button img {
  height: 26px;
}

.d-none {
  display: none;
}

.kratos-entry-footer .footer-tag a {
  background-color: #aaa;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 13px;
  padding: 2px 5px;
}
.kratos-entry-footer .footer-tag a:focus, .kratos-entry-footer .footer-tag a:hover {
  background: #8d8d8d;
  color: #fff !important;
}
.kratos-entry-footer .footer-tag .pull-date {
  float: right;
  margin-top: 5px;
  font-size: 14px;
}

.share-wrap {
  display: block;
  margin: 10px 0 -20px;
}
.share-plain, .share-wrap {
  position: relative;
  text-align: center;
}
.share-plain {
  display: inline-block !important;
  box-sizing: border-box;
  margin: 4px 3px !important;
  padding: 0;
  width: 2pc !important;
  height: 2pc !important;
  border: 1px solid #bdc4d6;
  border-radius: 30px !important;
  color: #bdc4d6;
  white-space: nowrap;
  line-height: 30px !important;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-width: 1px;
  background-color: transparent;
}
.share-plain .icon-wrap {
  vertical-align: middle;
  text-align: center;
  line-height: 30px;
}
.share-plain .share-int {
  position: absolute;
  bottom: 50px;
  left: 50%;
  z-index: 8;
  display: none;
  margin-left: -110px;
  padding: 10px;
  width: 220px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #555;
  text-align: center;
  opacity: 0.2;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.share-plain .share-int:after {
  position: absolute;
  margin-left: -11px;
  bottom: -10px;
  content: "";
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
}
.share-plain .share-int canvas, .share-plain .share-int img {
  margin: 15px;
  height: auto;
  max-width: 100%;
}
.share-plain .share-int p {
  color: #676767;
  white-space: normal;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
}
.share-plain .share-int img {
  display: inline-block !important;
}
.share-plain.pop:hover .share-int {
  display: block;
  opacity: 1;
}

@keyframes widget-title-icon-animation {
  0%, 25%, 50%, 75%, 100% {
    transform: rotate(0);
  }
  12%, 63% {
    transform: rotate(10deg);
  }
  37%, 88% {
    transform: rotate(-10deg);
  }
}
@-webkit-keyframes widget-title-icon-animation {
  0%, 25%, 50%, 75%, 100% {
    -webkit-transform: rotate(0);
  }
  12%, 63% {
    -webkit-transform: rotate(10deg);
  }
  37%, 88% {
    -webkit-transform: rotate(-10deg);
  }
}
#kratos-widget-area {
  user-select: none;
}
#kratos-widget-area > .sticky-area {
  top: 56px;
  position: sticky;
  transition: 0.5s all;
}
#kratos-widget-area .widget {
  margin-bottom: 15px;
  padding: 20px 25px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
#kratos-widget-area .widget:last-child {
  margin: 0;
}
#kratos-widget-area .widget > .widget-title {
  position: relative;
  margin: 0 0 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 16px;
}
#kratos-widget-area .widget > .widget-title:not(.no-after)::after, #kratos-widget-area .widget > .widget-title > .toc-progress-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2.5px;
  width: 100px;
  background-color: #51aded;
  content: "";
  height: 2px !important;
}
#kratos-widget-area .widget > .widget-title i {
  margin-right: 10px;
}
#kratos-widget-area .widget:hover > .widget-title i {
  animation: widget-title-icon-animation 0.5s linear;
  -webkit-animation: widget-title-icon-animation 0.5s linear;
}
#kratos-widget-area .widget img {
  max-width: 100%;
  height: auto;
}
#kratos-widget-area .widget a {
  color: #666;
  text-decoration: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
#kratos-widget-area .widget a:hover {
  color: #618eba;
}
#kratos-widget-area .widget li {
  padding-top: 2px;
  padding-bottom: 2px;
}
#kratos-widget-area .widget .children {
  padding-left: 30px;
}
#kratos-widget-area .widget.widget-kratos-about {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
#kratos-widget-area .widget.widget-kratos-about .photo-background {
  -webkit-background-size: cover;
  background-size: cover;
}
#kratos-widget-area .widget.widget-kratos-about .photo-wrapper {
  width: 100%;
}
#kratos-widget-area .widget.widget-kratos-about .photo-wrapper img {
  margin: -50px 0 0;
  width: 90px;
  height: 90px;
  object-fit: cover;
  border: rgba(255, 255, 255, 0.4) 4px solid;
  border-radius: 50%;
}
#kratos-widget-area .widget.widget-kratos-about .photo-background {
  height: 120px;
}
#kratos-widget-area .widget.widget-kratos-about .textwidget {
  margin: 10px 0 30px 0;
}
#kratos-widget-area .widget.widget-kratos-about p {
  margin-bottom: 1em;
  font-size: 16px;
  margin: 20px;
}
#kratos-widget-area .widget.widget-kratos-about .site-meta {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#kratos-widget-area .widget.widget-kratos-about .site-meta > .meta-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}
#kratos-widget-area .widget.widget-kratos-about .site-meta > .meta-item:hover {
  background-color: rgba(239, 239, 239, 0.6666666667);
}
#kratos-widget-area .widget.widget-kratos-about .site-meta > .meta-item > * {
  display: flex;
  justify-content: center;
}
#kratos-widget-area .widget.widget-kratos-ad {
  padding: 5px;
}
#kratos-widget-area .widget.widget-kratos-ad .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
#kratos-widget-area .widget.widget-kratos-ad h4 {
  margin: 10px 20px 5px;
}
#kratos-widget-area .widget.widget-kratos-ad img {
  max-width: 100%;
  height: auto;
}
#kratos-widget-area .widget.widget-kratos-tags .tag-clouds a {
  display: inline-block;
  margin: 3px 4px;
  padding: 4px 12px;
  border: 1px solid #f0f0f0;
  text-transform: uppercase;
  line-height: 19px;
  background: rgba(251, 251, 251, 0.7);
  border-radius: 5px;
}
#kratos-widget-area .widget.widget-kratos-posts .tab-content {
  margin: 0 8px -18px 8px;
}
#kratos-widget-area .widget.widget-kratos-posts .tab-content > .tab-pane {
  display: none;
}
#kratos-widget-area .widget.widget-kratos-posts .tab-content > .active {
  display: block;
}
#kratos-widget-area .widget.widget-kratos-posts .tab-content .list-group a {
  overflow: hidden;
  border-bottom: 1px dashed #b4afaf;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
#kratos-widget-area .widget.widget-kratos-posts .tab-content .list-group a:last-child {
  border-bottom: 0;
}
#kratos-widget-area .widget.widget-kratos-posts .list-group {
  padding-left: 0;
  margin-bottom: 20px;
}
#kratos-widget-area .widget.widget-kratos-posts .list-group-item {
  position: relative;
  display: block;
  margin-bottom: 0;
  padding: 8px 0;
  border: 0;
  background-color: unset;
}
#kratos-widget-area .widget.widget-kratos-posts .list-group-item:first-child {
  margin-top: 1px;
}
#kratos-widget-area .widget.widget-kratos-posts .list-group-item i {
  margin: 0 5px 0 0;
}
#kratos-widget-area .widget.widget-kratos-archive ul, #kratos-widget-area .widget.widget-kratos-categories ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#kratos-widget-area .widget.widget-kratos-archive li::before, #kratos-widget-area .widget.widget-kratos-categories li::before {
  font-family: fontawesome;
  font-size: 15px;
  margin-right: 8px;
}
#kratos-widget-area .widget.widget-kratos-archive li::before {
  content: "\f133";
}
#kratos-widget-area .widget.widget-kratos-categories li {
  margin-left: 10px;
}
#kratos-widget-area .widget.widget-kratos-categories li::before {
  content: "\f114";
}
#kratos-widget-area .widget.widget-kratos-categories .category-list-item .category-list-count {
  float: right;
  margin-right: 20px;
  border-radius: 6px;
  width: 2em;
  text-align: center;
}
#kratos-widget-area #krw-toc {
  display: block !important;
}
#kratos-widget-area #krw-toc .toc {
  padding: 0;
  overflow: auto;
}
#kratos-widget-area #krw-toc .toc-item > a {
  display: block;
  padding-left: 1em;
  position: relative;
}
#kratos-widget-area #krw-toc .toc-item:not(.show) > .toc-child {
  display: none;
}
#kratos-widget-area #krw-toc .toc-item.active > a {
  color: #fff;
  background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}
#kratos-widget-area #krw-toc .toc-item.active > a::before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 10px;
  margin: auto;
  left: 0;
  border-left: 6px solid;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}
#kratos-widget-area #krw-toc .toc-item.active > a::before {
  border-left-color: #fff;
}

.widget-kratos-toc .toc-child, .kratos-post-inner-toc .toc-child {
  padding-left: 1rem;
}

body.nav-up #kratos-widget-area > .sticky-area {
  top: 15px;
}

.toc li {
  list-style: none;
  padding: 0;
}

.kr-tool .tool, .kr-tool .box {
  right: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.kr-tool .tool {
  bottom: 20px;
  position: fixed;
}
.kr-tool .box {
  background: #848484;
  margin-top: 10px;
  opacity: 0.7;
}
.kr-tool .box:hover {
  background: #656565;
  cursor: pointer;
}
.kr-tool .box span {
  width: 30px;
  height: 30px;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 15px;
}
.kr-tool .box a {
  display: contents;
}
.kr-tool .box.gotop-box {
  bottom: -50px;
  position: fixed;
}

body.scroll-down .kr-tool .tool {
  bottom: 60px;
}
body.scroll-down .kr-tool .box.gotop-box {
  bottom: 20px;
}

#footer {
  background: #23282d;
  padding-top: 40px;
}
#footer.ap-lrc {
  padding-bottom: 32px;
}
#footer li {
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
}
#footer li a {
  color: rgba(255, 255, 255, 0.5);
}
#footer .kratos-social-icons {
  padding-left: 0;
}
#footer .kratos-social-icons li {
  padding: 4px 14px;
}
#footer .kratos-social-icons li a {
  display: inline-block;
  text-decoration: none;
}
#footer .kratos-social-icons li a > i {
  font-size: 36px;
}
#footer .kratos-social-icons li a > :not(i) {
  width: 36px;
  height: 36px;
}
#footer .kratos-social-icons li a > * {
  display: inline-block;
  vertical-align: middle;
}
#footer .kratos-copyright {
  padding-left: 0;
  list-style: none;
}
#footer .kratos-copyright div {
  line-height: 1.2;
  margin: 0 40px;
}
#footer .kratos-copyright li {
  color: rgba(255, 255, 255, 0.3);
  line-height: 1;
  margin: 0 2px;
  font-size: 12px;
}
#footer .kratos-copyright:first-child {
  margin-top: 25px;
}

@media (min-width: 992px) {
  #footer .col-md-6 {
    width: 80%;
    margin: auto 10%;
  }
}
.linkpage ul {
  color: rgba(255, 255, 255, 0.15);
  padding: 0;
}
.linkpage ul:after {
  content: " ";
  clear: both;
  display: block;
}
.linkpage ul li {
  float: left;
  width: 48%;
  position: relative;
  transition: all 0.3s ease-out;
  border-radius: 5px;
  line-height: 1.3;
  height: 90px;
  display: block;
}
.linkpage ul li:hover {
  background: rgba(230, 244, 250, 0.5);
  cursor: pointer;
}
.linkpage ul li a {
  padding: 0 10px 0 90px;
}
.linkpage ul li a img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
  margin: auto;
  border: none;
}
.linkpage ul li a h4 {
  color: #333;
  font-size: 18px;
  margin: 0 0 7px 0;
  padding-left: 90px;
}
.linkpage ul li a p {
  font-size: 12px;
  color: #999;
  padding-left: 75px;
}
.linkpage ul li a h4, .linkpage ul li a p {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.4;
}
.linkpage ul li a:hover h4 {
  color: #51aded;
}
.linkpage h3 {
  margin: 15px -25px;
  padding: 0 25px;
  border-left: 5px solid #51aded;
  background-color: #f7f7f7;
  font-size: 25px;
  line-height: 40px;
}

@media (max-width: 1200px) {
  .kratos-entry-content-new {
    height: 80px;
  }
}
@media (max-width: 992px) {
  .kratos-post-meta span {
    font-size: 12px;
  }
  .kratos-post-meta span i:not(:first-child) {
    margin-left: 10px;
  }
  .kratos-entry-content-new {
    height: 100px;
  }
  .title-h2 {
    padding: 0 15px;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 25px !important;
  }
  h2 {
    font-size: 21px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 15px;
  }
  .kratos-nav-toggle {
    display: block;
  }
  .color-logo {
    text-align: center;
    width: 100%;
    font-size: 25px;
    font-weight: 400;
    height: 60px;
    padding: 7px;
  }
  .color-logo a, .color-logo a:hover {
    color: #fff !important;
  }
  .color-logo img {
    max-width: 175px;
    height: 35px;
  }
  .kratos-cover {
    height: inherit;
    padding: 3em 0;
  }
  .kratos-cover.kratos-cover-2.text-center {
    display: none;
  }
  .kratos-start {
    height: 45px !important;
  }
  .kratos-overlay {
    display: none;
  }
  #kratos-header {
    background: rgba(22, 23, 26, 0.9);
  }
  #kratos-blog-post {
    padding: 2em 0 1em;
  }
  .kratos-post-meta span {
    font-size: 9pt;
  }
  .kratos-post-meta span i:not(:first-child) {
    margin-left: 5px;
  }
  #kratos-menu-wrap, .kratos-post-inner-new header .label {
    display: none;
  }
  .kratos-post-content embed {
    height: auto;
  }
  h1.kratos-entry-title.text-center {
    font-size: 36px !important;
  }
  .kratos-entry-thumb-new {
    position: relative;
    float: none;
    width: auto;
    height: auto;
    margin: 0 0 20px 0;
    padding: 0 0 60% 0;
  }
  .kratos-entry-thumb-new img {
    padding: 0;
    border: 0;
  }
  .kratos-post-inner-new {
    margin-left: 0;
    height: auto;
    padding: 0 20px;
  }
  .kratos-post-meta-new .read-more {
    margin-right: 8px;
  }
  .kratos-entry-content-new {
    height: auto;
  }
  .hide_notice, .info {
    font-size: 12px;
  }
  #archives .arc-t {
    width: 45%;
  }
}
@media (max-width: 768px) {
  .visible-xs {
    display: block !important;
  }
  .hidden-xs {
    display: none !important;
  }
}
@media (max-width: 530px) {
  #slide .carousel-indicators {
    margin-bottom: 0;
  }
  .kratos-entry-footer .post-like-donate a {
    width: 100px;
  }
  .kratos-entry-footer .footer-tag a {
    padding: 2px 4px;
  }
  .kratos-post-meta .hd,
.pull-date,
.spig,
.useragent .bversion {
    display: none;
  }
  .pagination li a {
    width: 30px;
    padding: 4px;
    margin-left: 5px;
  }
  #archives .arc-t {
    width: 97%;
  }
}
@media (max-width: 460px) {
  .linkpage ul li {
    width: 97%;
  }
  .linkpage ul {
    padding-left: 5px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
  #kratos-desktop-topnav.color-banner {
    height: 71px;
  }
  .kratos-start.kratos-hero {
    height: 70px;
  }
  .color-banner #kratos-menu-wrap {
    float: right;
    width: 80%;
  }
  .color-banner .nav-header {
    padding: 14px 0;
  }
  #kratos-mobile-topnav {
    display: none;
  }
  .pagination > li > a {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .kratos-hentry,
.navigation div,
#kratos-widget-area .widget {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  [class*=col-md-] {
    float: left;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .page404 .errtxt {
    margin-top: 15%;
  }
  .page404 .errtxt h5, .page404 .errtxt p {
    margin-top: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  .visible-lg {
    display: block !important;
  }
  .hidden-lg {
    display: none !important;
  }
}
@media print {
  body {
    background: #fff !important;
  }
  .col-md-8,
#main,
.kratos-post-inner {
    padding: 0;
  }
  #kratos-header,
.kratos-start,
#kratos-widget-area,
.kr-tool {
    display: none;
  }
}
td.code {
  text-align: left;
}

.twitter-tweet {
  margin: auto;
}

#dsqjs {
  padding: 10px 15px;
}
#dsqjs-msg {
  color: #2a2e2e;
  background-color: #ebeef2;
  padding: 10px;
  border-radius: 5px;
}

.post-comments {
  margin-top: 16px;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  word-break: break-all;
}

.index-post h1 {
  margin: 0 0 15px;
}
.index-post .kratos-entry-title {
  font-size: 36px;
  position: relative;
}
.index-post .kratos-entry-header .kratos-entry-title::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}
.index-post .kratos-entry-header .kratos-entry-title:hover::after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}
.index-post .kratos-entry-thumb-new {
  position: relative;
  float: none;
  width: auto;
  height: auto;
  margin: 0 0 20px 0;
  padding: 0 0 60% 0;
}
.index-post .kratos-entry-thumb-new img {
  padding: 0;
  border: 0;
}

span.blur {
  text-shadow: 0 0 0.5em rgb(0, 0, 0);
  color: rgba(1, 1, 1, 0.1) !important;
  transition: 0.2s;
}
span.blur:hover {
  text-shadow: 0 0 0;
  color: #000000 !important;
}

/**************** Search Page related ****************/
#kr-search-notice.alert {
  transition: 0.3s;
}

#result-posts {
  margin-top: 20px;
}
#result-posts .kr-search-result {
  margin: auto;
  height: auto;
  width: 90%;
  padding-top: 22px;
}
#result-posts .kr-search-result .kratos-entry-content-new {
  height: auto;
}
#result-posts m {
  color: #333;
  background-color: yellow;
}

#search-input {
  -webkit-appearance: none;
  border-color: #dbdbdb;
  background-color: #fff;
  border-radius: 4px 0 0 4px;
}
#search-input:hover {
  border-color: #b5b5b5;
}
#search-input:focus {
  outline: 0;
}
#search-input:active, #search-input:focus {
  border-color: #3273dc;
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}

.input-group .form-input.input-lg {
  display: inline-flex;
  box-shadow: none;
  flex: auto;
  justify-content: flex-start;
  padding: 0 8px;
  font-size: 15px;
  max-width: 100%;
  border: 1px solid transparent;
}
.input-group .btn.btn-primary {
  border: transparent;
  border-radius: 0 4px 4px 0;
}

.search-demo-tags a {
  margin: 5px 10px;
}

/**************** Plugins *****************/
span.mjx-chtml.MJXc-display {
  color: #000000;
}

#hexo-blog-encrypt label {
  padding-left: 10px;
  pointer-events: none;
}
#hexo-blog-encrypt #hbePass {
  padding: 0;
  border-radius: 4px;
}

mjx-container {
  display: inline-block;
  vertical-align: bottom;
  overflow-x: auto;
  max-width: 100%;
}

/******************* Images ********************/
body.custom-background {
  background-size: cover;
  background-attachment: fixed;
}

/******************* Color ********************/
*::-webkit-selection {
  color: #fcfcfc;
  background: #262a30;
}
*::-moz-selection {
  color: #fcfcfc;
  background: #262a30;
}
*::selection {
  color: #fcfcfc;
  background: #262a30;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
p {
  color: #000;
}

body {
  background-color: #f5f5f5;
}

h2.title-h2 {
  background-color: rgba(239, 239, 239, 0.6666666667);
}

a {
  color: #1e8cdb;
}

blockquote {
  background-color: rgba(239, 239, 239, 0.6666666667);
  border-color: rgba(60, 73, 91, 0.2274509804);
}
blockquote p {
  color: #000;
}

table th {
  color: #000;
  background-color: rgba(239, 239, 239, 0.6666666667);
}

.kratos-hentry {
  background-color: rgba(255, 255, 255, 0.8666666667);
}

.kratos-entry-border-new a, .kratos-entry-border-new i {
  color: #000;
}
.kratos-entry-border-new .kratos-post-meta-new {
  border-top: 1px solid rgba(60, 73, 91, 0.2274509804);
  background: rgba(255, 255, 255, 0.8666666667);
}
.kratos-entry-border-new .kratos-status i {
  color: #666;
}
.kratos-entry-border-new .kratos-status p a {
  color: #1e8cdb;
}
.kratos-entry-border-new .kratos-status p a:hover {
  color: #6ec3f5;
}

.kratos-entry-header span, .kratos-entry-header a {
  color: #000;
}

ul.kratos-post-meta {
  color: #666;
}
ul.kratos-post-meta span, ul.kratos-post-meta a {
  color: #666;
}

.kratos-post-content p, .kratos-post-content table td {
  color: #000;
}

.kratos-post-inner .kratos-copyright {
  background-color: rgba(239, 239, 239, 0.6666666667);
}
.kratos-post-inner .kratos-copyright h5 {
  color: #000;
}
.kratos-post-inner .kratos-copyright a {
  color: #000;
}

.kratos-entry-footer .footer-tag a {
  color: #000;
  background-color: rgba(255, 255, 255, 0.8666666667);
}
.kratos-entry-footer .pull-date span {
  color: #000;
}

.post-navigation a {
  color: #000;
}

#kratos-widget-area .widget a, #kratos-widget-area .widget-title i {
  color: #666;
}
#kratos-widget-area .widget.widget-kratos-about .site-meta > .meta-item:hover {
  background-color: rgba(239, 239, 239, 0.6666666667);
}
#kratos-widget-area .widget.widget-kratos-tags .tag-clouds a {
  background-color: rgba(255, 255, 255, 0.8666666667);
}
#kratos-widget-area .widget.widget-kratos-tags .tag-clouds a:hover {
  color: rgba(255, 255, 255, 0.8666666667);
  background-color: #666;
}
#kratos-widget-area .widget.widget-kratos-categories .category-list-item::before {
  color: #666;
}
#kratos-widget-area .widget.widget-kratos-categories .category-list-item .category-list-count {
  color: #000;
  background-color: rgba(239, 239, 239, 0.6666666667);
}

.pagination a, .pagination span {
  background-color: rgba(255, 255, 255, 0.8666666667);
  color: #000;
}
.pagination > li > a {
  background-color: rgba(255, 255, 255, 0.8);
}

.kratos-hentry, .navigation div, #kratos-widget-area .widget {
  background-color: rgba(255, 255, 255, 0.8);
}

.arc-tags a {
  color: #666 !important;
  background-color: rgba(255, 255, 255, 0.8666666667) !important;
  border-color: rgba(60, 73, 91, 0.2274509804) !important;
}
.arc-tags a:hover {
  color: #6ec3f5 !important;
}

.linkpage ul li a h4 {
  color: #000;
}
.linkpage ul li:hover {
  background-color: rgba(239, 239, 239, 0.6666666667);
}

.kratos-post-header-title {
  color: #000;
}

.xControl {
  background-color: rgba(239, 239, 239, 0.6666666667);
}
.xControl .xHeading span {
  color: #000;
}

.index-post .kratos-entry-header .kratos-entry-title {
  color: #000;
}
.index-post .kratos-entry-header .kratos-entry-title::after {
  background-color: #000;
}

#archives .posts-collapse .post-title a,
.categories > ul.category-list > li.category-list-item a {
  color: #666;
}

.post-comments {
  background-color: rgba(255, 255, 255, 0.8666666667);
}

a:hover,
.kratos-entry-border-new a:hover,
.kratos-entry-header .kratos-post-meta a:hover,
.kratos-post-inner .kratos-copyright a:hover,
#footer li a:hover,
#archives .posts-collapse .post-title a:hover {
  color: #6ec3f5;
}

#kratos-desktop-topnav {
  background: rgba(40, 42, 44, 0.6);
}

#kratos-mobile-topnav {
  background: rgba(22, 23, 26, 0.9019607843);
}

.toc-item.active > a {
  color: rgba(255, 255, 255, 0.8666666667);
}
.toc-item.active > a::before {
  border-left-color: rgba(255, 255, 255, 0.8666666667);
}

span.blur {
  text-shadow: 0 0 0.5em #000;
}
span.blur:hover {
  color: #000 !important;
}

/******************* Plugins ********************/
#dsqjs a {
  color: #1e8cdb;
}
#dsqjs .dsqjs-tab-active {
  color: #000;
}
#dsqjs-msg {
  color: #000;
  background-color: rgba(255, 255, 255, 0.8666666667);
}

.post-comments .v[data-class=v] p,
.post-comments .v[data-class=v] .vheader label,
.post-comments .v[data-class=v] .veditor, .post-comments .v[data-class=v] .veditor::placeholder {
  color: #000;
}
.post-comments .v[data-class=v] .vbtn,
.post-comments .v[data-class=v] .vcount,
.post-comments .v[data-class=v] .vcard .vhead span.vnick,
.post-comments .v[data-class=v] .vcard .vhead .vtime {
  color: #666;
}
.post-comments .v[data-class=v] .vicon,
.post-comments .v[data-class=v] .vcard .vhead .vreply svg {
  fill: #666;
}
.post-comments .v[data-class=v] .vpanel {
  background-color: rgba(255, 255, 255, 0.8666666667);
}
.post-comments .v[data-class=v] .veditor:focus, .post-comments .v[data-class=v] .vinput:focus {
  background-color: rgba(239, 239, 239, 0.6666666667);
}

.aplayer * {
  color: unset;
}

span.mjx-chtml.MJXc-display {
  color: #000;
}

/******************* Images ********************/
.widget-kratos-about .photo-background {
  background-image: url("../images/about.webp");
}

.kratos-cover.kratos-cover-2 {
  background-image: url("../images/banner.webp");
}

@media (min-width: 768px) {
  body.custom-background {
    background-image: url("../images/bg.webp");
  }
}
.post-comments.bg-image textarea {
  background: url("../images/comment.webp") right bottom no-repeat !important;
}